<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- START -->
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="address">Address</vs-th>
          <vs-th sort-key="phone">Phone</vs-th>
          <vs-th sort-key="bill_name">Billing To</vs-th>
          <vs-th sort-key="created_at"></vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <a @click="openInfo(tr.id)" class="pointer">{{tr.name}}</a>
            </vs-td>
            <vs-td label="Address" :data="tr.address">{{tr.address}}</vs-td>
            <vs-td label="Phone" :data="tr.phone">{{tr.phone}}</vs-td>
            <vs-td label="Billing To" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
            <vs-td :data="tr.id" width="10">
              <div class="flex">
                <vs-button size="small" :to="{name:'contacts-edit', params:{id: tr.id}}" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
                <vs-button v-if="!tr.company_contact_id && tr.id!=id" size="small" @click="deletes(tr.address_id)" type="line" icon-pack="feather" icon="icon-trash"></vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      <!-- END -->
    </div>
    <vs-popup title="Information" :active.sync="isOpenInfo">
      <info-contact :id="this.id_detail"></info-contact>
    </vs-popup>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import InfoContact from './Info.vue'
export default {
  props: ['id'],
  components:{
    InfoContact
  },
  data(){
    return {
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      id_detail: null,
      isOpenInfo: false
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.contacts.a_rows
    })
  },
  methods:{
    ...mapActions({
      dispatchContactIndex: 'contacts/address_list',
      // dispatchDestroy: 'contacts/destroy'
      dispatchDestroy: 'contacts/destroyAddressList'
    }),
    async getData(){
      let payload = {
        id: this.id,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchContactIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    openInfo(id){
      this.id_detail = id
      this.isOpenInfo = true
    },
    async confirmDelete(){
      try {
        let payload = {
          contact_id: this.id,
          address_id: this.idDelete
        }
        await this.dispatchDestroy(payload)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>