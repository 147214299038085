<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Information" class="mb-5">
        <template slot="actions">
          <vs-button size="small" :to="{name:'contacts-edit', params:{id: $route.params.id}}" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
        </template>
        <info-contact :id="$route.params.id"></info-contact>
      </vx-card>
    </div>
  </div>
</template>

<script>
import InfoContact from './sections/Info.vue'
import {mapActions} from 'vuex'
export default {
  data() {
    return {
        is_customer : null,
    }
  },
  components:{
    InfoContact
  },
  methods:{
    ...mapActions({
      dispatchShow: 'contacts/show'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.id)
      this.is_customer = data.is_customer // old = this.data.is_customer
     }
  },
  async created() {
  }
}
</script>

<style>

</style>