<template>
  <vs-tabs>
      <vs-tab label='Detail'>
          <div class="vx-col flex">
                <div class="">
                  <table>
                    <tr>
                      <td class="font-semibold">Contact Name</td>
                      <td>{{data.name}}</td>
                    </tr>
                    <tr v-if='data.is_driver'>
                      <td class="font-semibold">Username</td>
                      <td>{{data.username}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Address</td>
                      <td>{{data.address}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Phone</td>
                      <td>{{data.phone}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Email Address</td>
                      <td>{{data.email}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Website</td>
                      <td>{{data.website}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Contact Person</td>
                      <td>{{data.contact_person}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Contact Person Phone</td>
                      <td>{{data.contact_person_no}}</td>
                    </tr>
                  </table>
                </div>
                <div class="ml-13">
                  <span v-if='is_contact'>     
                      <table>
                        <tr>
                          <td class="font-semibold">Vendor</td>
                          <td><vs-checkbox v-model="data.is_vendor" class="pointer-events-none"></vs-checkbox></td>
                        </tr>
                        <tr>
                          <td class="font-semibold">Customer</td>
                          <td><vs-checkbox v-model="data.is_customer" class="pointer-events-none"></vs-checkbox></td>
                        </tr>
                        <tr>
                          <td class="font-semibold">Driver</td>
                          <td><vs-checkbox v-model="data.is_driver" class="pointer-events-none"></vs-checkbox></td>
                        </tr>
                        <tr>
                          <td class="font-semibold">Helper</td>
                          <td><vs-checkbox v-model="data.is_helper" class="pointer-events-none"></vs-checkbox></td>
                        </tr>
                        <tr>
                          <td class="font-semibold">Sales</td>
                          <td><vs-checkbox v-model="data.is_sales" class="pointer-events-none"></vs-checkbox></td>
                        </tr>
                      </table>
                      <vs-divider></vs-divider>
                  </span>
                  <table>
                    <tr>
                      <td class="font-semibold">PKP</td>
                      <td><vs-checkbox v-model="data.is_pkp" class="pointer-events-none"></vs-checkbox></td>
                    </tr>
                    <tr>
                      <td class="font-semibold">NPWP</td>
                      <td>{{data.npwp}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Active Contact</td>
                      <td><vs-checkbox v-model="data.is_active" class="pointer-events-none"></vs-checkbox></td>
                    </tr>
                  </table>
                </div>
          </div>
          <div v-if='data.is_customer'>
              <span>
                    <vs-divider></vs-divider>
                    <vs-button :to="{name:'contacts-add-address', params: {id: $route.params.id}}">Add Address</vs-button>&nbsp;
                    <!-- <vs-button @click="findContact">Add From Existing Contact</vs-button> -->
                    <button-browse-contact label="Contact" v-on:onChangeContact="addExistingContact"></button-browse-contact>
                    <address-list :key="addressListComponentKey" :id="$route.params.id"></address-list>
                    
                    <!-- <vs-popup title="Find Contact" :active.sync="isPopUp">
                      <vs-table :sst="true" search @search="handleSearch" :data="contacts.data" @sort="handleSort" class="mb-3" >
                        <template slot="thead">
                          <vs-th sort-key="name">Name</vs-th>
                          <vs-th sort-key="address">Address</vs-th>
                          <vs-th sort-key="phone">Phone</vs-th>
                        </template>
                        <template slot-scope="{data}">
                          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="tr.name">
                              <a class="pointer" @click="selectContact(tr.id)">{{tr.name}}</a>
                            </vs-td>
                            <vs-td :data="tr.address">{{tr.address}}</vs-td>
                            <vs-td :data="tr.phone">{{tr.phone}}</vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                      <vs-pagination :total="contacts.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
                    </vs-popup> -->
              </span>
          </div>
      </vs-tab>

      <vs-tab label='Vehicles' v-if='data.is_driver'>
          <vehicle :driver_id='$route.params.id' />
      </vs-tab>

      <vs-tab label='Shipments' v-if='data.is_driver'>
          <shipment :driver_id='$route.params.id' />
      </vs-tab>

      <vs-tab label='Tracking' v-if='data.is_driver'>
          <map-shipment :internal_driver_id='$route.params.id' />
      </vs-tab>

      <vs-tab label='User Management' v-if='data.is_customer || data.is_vendor'>
          <user :contact_id='$route.params.id' />
      </vs-tab>
  </vs-tabs>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import AddressList from './AddressList.vue'
import Vehicle from '@pages/setting/vehicles/Index.vue'
import User from '@pages/setting/users/Index.vue'
import Shipment from '@pages/operational/shipments/Index.vue'
import MapShipment from '@pages/operational/map_shipments/Index.vue'
import ButtonBrowseContact from '@browse_button/Contact.vue'

export default {
  components:{
    AddressList,
    Shipment,
    MapShipment,
    User,
    Vehicle,
    ButtonBrowseContact
  },
  props:['id', 'is_driver_contact'],
  data(){
    return {
      data: {},
      is_contact : null,
      // currentPage: 1,
      // search:null,
      // sortKey:null,
      // sortVal:null,
      // isPopUp: false,
      addressListComponentKey: 0
    }
  },
  watch:{
    id(val){
      if(val) return this.getData();
    }
  },
  computed:{
    ...mapState({
      contacts: state => state.contacts.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchShow: 'contacts/show',
      dispatchContactIndex: 'contacts/index',
      dispatchFromExistingContact: 'contacts/storeFromExistingContact'
    }),
    reRenderAddressListComponent() {
      this.addressListComponentKey++;
    },
    // async findContact(){
    //   await this.getDataContact()
    //   this.isPopUp=true
    // },
    async addExistingContact(value) {
      let payload = {
          contact_id: this.id,
          address_id: value
        }
        try {
          await this.dispatchFromExistingContact(payload)
          this.reRenderAddressListComponent();
          this.$vs.notify({
            title: 'Success',
            text: 'Your data has been saved successfully',
            color: 'primary'
          })
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
    },
    // async selectContact(value) {
    //   let payload = {
    //       contact_id: this.id,
    //       address_id: value
    //     }
    //     try {
    //       let {message} = await this.dispatchFromExistingContact(payload)
    //       this.reRenderAddressListComponent();
    //       this.isPopUp=false
    //       this.$vs.notify({
    //         title: 'Success',
    //         text: 'Your data has been saved successfully',
    //         color: 'primary'
    //       })
    //     } catch (error) {
    //       this.$vs.notify({
    //         title: 'Oops!',
    //         text: error.data.message,
    //         color: 'danger'
    //       })
    //     }
    // },
    // async getDataContact() {
    //   let payload = {
    //       page: this.currentPage,
    //       search: this.search,
    //       sortKey: this.sortKey,
    //       sortVal: this.sortVal
    //     }
    //   await this.dispatchContactIndex(payload)
    // },
    // handleSearch(val){
    //   this.search = val
    // },
    // handleSort(key,val){
    //   this.sortKey = key
    //   this.sortVal = val
    //   this.getData()
    // },
    async getData(){
      let { data } = await this.dispatchShow(this.id)
      this.data = data
    }
  },
  created(){
    if(this.id) this.getData();
    this.is_driver_contact = this.$route.name == 'driver_contacts-show'
    if(!this.is_driver_contact) {
      this.is_contact = true
    }
  }
}
</script>

<style lang="scss">
  table {
    td {
      vertical-align: top;
      min-width: 160px;
      padding-bottom: .8rem;
      word-break: break-all;
    }
  }
</style>